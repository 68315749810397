@font-face {
  font-family: 'Deep Sea';
  src: URL('./asset/DeepSea.ttf') format('truetype');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 2s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Container {
  margin-top: 0px;
  padding-top: 5px;
  padding-bottom: 40px;
  color: white;
  font-family: 'Deep Sea';
}
.Container-Admin {
  margin-top: 20px;
  margin-left: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: max-content;
}
.Container-Admin>.title {
  font-size: 24px;
  color: white;
  margin-left: 10px;
  margin-bottom: 20px;
}
.Container-Admin .block-input {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.Container-Admin .block-input .label{
  color: #ffffff;
  width: 70px;
  text-align: right;
}
.Container-Admin .block-input .input{
  margin-left: 5px;
}
.Container-Admin .table{
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  margin-left: 20px;
  padding-top: 5px;
  padding-bottom: 40px;
  color: white;
  font-family: 'Verdana';
}
.Container-Admin .table .header {
  display: flex;
  flex-direction: row;
}
.Container-Admin .table .header div {
  width: 100px !important;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-width: 1px;
  text-align: center;
}
.Container-Admin .table .header div:first-child {
  width: 50px !important;
  border-right-style: solid;
  border-top-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-width: 1px;
}
.Container-Admin .table .body{
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}
.Container-Admin .table .empty{
  text-align: center;
  margin-top: 40px;
}
.Container-Admin .table .row{
  display: flex;
  flex-direction: row;
  color: white;
  font-family: 'Verdana';
  font-size: 12px;
}
.Container-Admin .table .row .data{
  width: 100px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-color: white;
  border-width: 1px;
  text-align: center;
  overflow-wrap: anywhere;
}
.Container-Admin .table .row .data:first-child {
  width: 50px !important;
  border-right-style: solid;
  border-top-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-width: 1px;
}
.Container-Admin .table .row .status-wait{
  color: white
}
.Container-Admin .table .row .status-paid{
  color: yellow;
  background-color: green;
}
.Container-Admin .table .row .status-cancel{
  color: red;
  background-color: #FADBD8;
}

.Container-Admin .table .row button {
  cursor: pointer;
}

.Container-Login {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  padding-top: 5px;
  padding-bottom: 40px;
  color: white;
  font-size: 14;
  width: 280px;
}
.Container-Login .title{
  font-size: 16;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.Container-Login .row-input{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.Container-Login .row-input .label{
width: 100px;
text-align: right;
}
.Container-Login .row-input .value{
  text-align: left;
  margin-left: 5px;
  }
.Container-Login .row-buttons{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: center;
}
.Container-Login input{
  width: 140px;
  background-color: #ffffcc;
}
.Container-Login button{
  cursor: pointer;
  padding: 7px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

.block-new-button {
  position: absolute;
  left: 500px;
  top: 0px;
}
.block-new-button button{
  cursor: pointer;
  background-color: #ffffff;
  border-color: #333333;
  color: blue;
  border-radius: 7px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
}


.Popup-Admin {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px;
}
.Popup-Admin .block-input {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.Popup-Admin .block-input .label{
  color: #000000;
  width: 120px;
  text-align: right;
}
.Popup-Admin .block-input .input{
  margin-left: 5px;
}

.Popup-action {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.Popup-action div {
  margin-bottom: 10px;
  text-align: center;
}
.Popup-action button {
  padding: 10px;
  width: 140px !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}
.Popup-action .bt-cancel {
  color: #ff0000;
}
.Popup-action .bt-paid {
  color: #1E8449;
}
.Popup-action .bt-discard {
  color: #000000;
}

.Popup-paid {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.Popup-paid .content {
  font-size: 14px;
  color: #000000;
  text-align: left;
  margin-bottom: 10px;
}
.Popup-paid .row-input{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.Popup-paid .row-input .label{
text-align: right;
}
.Popup-paid .row-input .value{
  text-align: left;
  margin-left: 5px;
}
.Popup-paid input{
  width: 100px;
  background-color: #ffffcc;
}
.Popup-paid .buttons {
  text-align: center;
  margin-top: 20px;
}
.Popup-paid button {
  padding: 7px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 2px;
  margin-right: 2px;
}


.Popup-confirm-delete {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.Popup-confirm-delete .content {
  font-size: 16px;
  color: #0000ff;
  text-align: center;
}
.Popup-confirm-delete .buttons {
  text-align: center;
  margin-top: 20px;
}
.Popup-confirm-delete button {
  padding: 7px;
  width: 50px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}

.Payment-Source-Active {
  border-width: 5px 5px 0px 5px;
  border-color: red;
  border-radius: 5px 5px 0px 0px;
  padding: 10px 10px 5px 10px;
  background-color: #ffffff;
  color: #000000;
  font-weight: bold;
  margin-right: 1px;
  cursor: pointer;
}

.Payment-Source-DisActive {
  border-width: 5px 5px 0px 5px;
  border-color: red;
  border-radius: 5px 5px 0px 0px;
  padding: 10px 10px 5px 10px;
  background-color: #333333;
  color: #999999;
  font-weight: normal;
  margin-right: 1px;
  cursor: pointer;
}

.Popup-Main {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}
.Popup-Main  .title{
  margin-bottom: 10px;
}
.Popup-Main  .detail{
  margin-bottom: 10px;
}
.Popup-Main .button{
  margin-top: 20px;
  background-color: #282c34;
  color: #ffffff;
  padding: 10px 20px 10px 20px;
  border-radius: 3px;
  width: fit-content;
  cursor: pointer
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Loading Indicator */
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #ccc;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
